<template>
  <div class="select-platform" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.45)">
    <div class="header-bar mb-30 flex justify-center align-center">
      <span class="fs-24 white bold mt-15 mr-10" style="opacity: .9;letter-spacing:2px;">选择企业</span>
    </div>

    <span class="white fs-14 logout" @click="logout">退出登录</span>

    <!-- 版心 -->
    <div class="page-center">
      <div class="platform-list flex justify-center flex-nowrap">
        <div
          class="platform-item ml-10 mr-10"
          :class="{active:item.id==activePlatform}"
          v-for="item in platformList"
          :key="item.id"
          :style="{visibility: item.id!=1?'hidden':'visible'}"
          @click="selectPlatform()"
          title="点击进入平台"
        >
          <img class="platform-icon" :src="item.imgSrc">
          {{item.name}}
        </div>
      </div>

      <div class="company-title flex justify-between">
        <span>企业入口</span>
        <div class="input-box flex align-center">
          <input class="input-inner ml-20" v-model="keyword" placeholder="搜索企业名称....">
          <div class="colse-box flex align-center">
            <i v-if="keyword" class="colse-icon el-icon-error" @click="keyword=''"></i>
          </div>
          <img class="input-icon" src="@/assets/imgs/login/search-icon.png" >
        </div>
      </div>

      <div class="company-list flex pl-10">
        <template v-if="loadingComps">
          <div class="w-100-p color-06E5FB mt-70 h-100-p flex justify-center">
            <span class="fs-20">
              <i class="el-icon-loading mr-5"></i>
              正在加载...
            </span>
          </div>
        </template>
        <div
          class="company-item text-1line"
          v-for="item in usefulCompanys"
          :key="item.compId"
          @click="selectCompany(item.compId)"
          :title="item.compName"
        >{{item.compName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { gianComplist, selectedComp } from '@/apis/common'
import { logout } from '@/apis/login'

export default {
  data () {
    return {
      activePlatform: 1,
      platformList: [
        { id: '1', name: '总览', imgSrc: require('@/assets/imgs/login/platform-icon-all.png') },
        { id: '2', name: '商砼', imgSrc: require('@/assets/imgs/login/platform-icon-hunningtu.png') },
        { id: '3', name: '砂浆', imgSrc: require('@/assets/imgs/login/platform-icon-shajiang.png') },
        { id: '4', name: '水泥', imgSrc: require('@/assets/imgs/login/platform-icon-shuini.png') },
        { id: '5', name: '车辆', imgSrc: require('@/assets/imgs/login/platform-icon-cheliang.png') }
      ],
      keyword: '',
      activeCompany: '1',
      companyList: [],
      loading: false,
      loadingComps: false
    }
  },

  computed: {
    usefulCompanys () {
      return this.companyList.filter(item => item.compName.includes(this.keyword))
    }
  },

  created () {
    this.$store.commit('account/RESET_USERINFO')
    selectedComp({ focusType: 2 }).finally((res) => {
      this.initComps()
    })
  },
  methods: {
    selectPlatform () {
      this.enterPlatform()
    },

    selectCompany (compId) {
      selectedComp({ focusType: 1, compIds: [compId] }).then((res) => {
        if (res.code == 200) {
          this.enterPlatform()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 初始化企业列表
    async initComps () {
      this.loadingComps = true
      const res = await gianComplist({ pageNum: 1, pageSize: 1000000 })
      this.loadingComps = false
      if (res.code == 200) {
        this.companyList = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },

    async enterPlatform () {
      this.loading = true
      await this.$store.dispatch('account/getUserInfo')
      this.loading = false
      this.$router.push(this.$route.query.redirect || '/')
    },

    // 注销token，清除account信息
    logout () {
      selectedComp({ focusType: 2 })
      logout().finally(() => {
        this.$store.dispatch('account/clearAcountInfo').then(() => {
          this.$router.push('/login')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div { box-sizing: border-box; }

.select-platform {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #000 url('~@/assets/imgs/common/dlv-bg.png') no-repeat center;

  .header-bar {
    overflow: hidden;
    height: 66px;
    background: url('~@/assets/imgs/login/header-bg.png') no-repeat center;
    background-size: 100% 100%;
  }

  .logout {
    position: fixed;
    top: 30px;
    right: 40px;
    cursor: pointer;
    opacity: .85;

    &:hover {
      opacity: 1;
    }
  }

  // 版心
  .page-center {
    width: 1280px;
    margin: 0 auto;
    height: calc(100% - 66px);
  }

  .platform-item {
    cursor: pointer;
    position: relative;
    width: 232px;
    height: 207px;
    background: #031523;
    border: 1px solid #014148;
    border-radius: 3px;
    line-height: 318px;
    text-align: center;
    font-weight: bold;
    color: #C3C5CA;
    // transition: all .4s ease;

    &:hover {
      color: #FFF;
      box-shadow: 0 0 6px 2px #00F6FF;
    }

    .platform-icon {
      position: absolute;
      width: 100px;
      height: 120px;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .color-06E5FB {
    color: #06E5FB;
  }
  .company-title {
    color: #06E5FB;
    font-size: 24px;
    line-height: 36px;
    margin: 49px 20px 14px 29px;
  }

  .company-item {
    width: 252px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    color: #C3C5CA;
    background: url('~@/assets/imgs/login/company-bg.png') no-repeat center;
    background-size: 232px 46px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 15px;
    // &.active, &:hover {
    //     color: #FFF;
    //     background: url('~@/assets/imgs/login/company-bg-active.png') no-repeat center;
    //     background-size: 252px 66px;
    // }
  }

  .company-list {
    min-height: calc(100vh - 207px - 96px - 49px - 14px - 36px - 30px );
  }

  // 搜索框
  .input-box {
    position: relative;
    width: 291px;
    height: 36px;
    border: 1px solid #00FFFF;
    border-radius: 18px;
    .input-icon {
      width: 18px;
      height: 17px;
    }
    .colse-box {
      width: 18px;
      height: 18px;
      margin: 0 5px;
    }
    .colse-icon {
      color: #06E5FB;
      font-size: 18px;
      cursor: pointer;
    }
    .input-inner {
      width: 205px;
      outline: none;
      background: none;
      outline: none;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
      &::placeholder {
        color: #73777C;
        font-size: 14px;
      }
      &:focus{
        border: none;
        background: none;
        outline: none;
      }
    }
  }
}
</style>
